import moment from 'moment';
moment.locale('en');

export async function formatDuration(seconds) {
    const hours = Math.floor(seconds / 3600);
    seconds -= hours * 3600;
    const minutes = Math.floor(seconds / 60);
    seconds -= minutes * 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${Math.floor(seconds).toString().padStart(2, '0')}`;
};

export function formatDuree(date_fin, date_debut, statut) {
    // Convertir les chaînes de date en objets moment pour un traitement plus facile
    let debut = moment(date_debut);
    let fin = statut === 'terminé' ? moment(date_fin) : moment(); // Utiliser le moment actuel si la tâche est en cours

    // Calculer la durée en utilisant moment.duration()
    let duree = moment.duration(fin.diff(debut));

    // Extraire les heures et les minutes de la durée
    const heures = Math.floor(duree.asHours());
    const minutes = duree.minutes();

    // Formater et retourner la durée sous forme de chaîne "hh:mm"
    return `${heures.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
};

export function formatDate(date){
    return moment.utc(date).format('LL, LT')
}